import React, { Component } from 'react'

import '../Card/Card.css'
import '../ArticleCard/ArticleCard.css'

import "animate.css/animate.min.css";

/**
 * Article Card component 
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
export default class ArticleBlock extends Component {
    render() {
        return(
            <article className="article-card-container animated fadeIn" style={{flexDirection: this.props.direction}}>
                {/* <img className="card-img" src={this.props.backgroundImage} alt={this.props.title}></img> */}
                <div 
                    className="card-img" 
                    style={{
                        backgroundImage: `url(${this.props.backgroundImage})`
                        }}>
                </div>
                
                <div className="card-text" style={{padding: this.props.padding}}>

                    <h4>{this.props.subtitle}</h4>
                    <h1>{this.props.title}</h1>
                    

                    <div className="card-content" style={{padding: this.props.padding}}>
                        {this.props.content}
                    </div>
                    <div>
                        <p><b>Read more &gt;</b></p>
                    </div>
                </div>

                
            </article>

        )
    }
}