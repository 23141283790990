import React, { Component } from 'react'
import { Link } from "gatsby";

import client from "../../client"
import { urlFor } from '../../utils/image-builder'

import "./Journal.css"
import "../Header/Header.css"

import ArticleCard from '../ArticleCard/ArticleCard'
import Header from '../Header/Header'

import HeaderImg from '../../assets/audio-1844798_1920.jpg'


/**
 *  Journal page component
 *  @version 1.0
 *  @author Nadia Mayangputri
 */ 
export default class Journal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Store fetched categories and posts data
            categories: [],
            posts: [],
            currentCategory: "",
            reloadKey: Math.random(),
            isLoading: true
        }

    }

    /**
     * Reload (Re-render page) component by updating reload key
     */
    reload = () => {
        this.setState({
                reloadKey: Math.random(),
                isLoading: true
            }
        )
        
      } 

    componentDidMount() {
        this.getCategories()
        this.getPosts()
        
    }

    componentDidUpdate(prevProps, prevState) {
        // Listens for url query change and update post list based on the chosen category
        if (prevState.reloadKey !== this.state.reloadKey) {
            this.getPosts()
          }

    }

    /**
     * Fetch posts from Sanity data store
     * @see {@link https://www.sanity.io/docs/js-client} for more information
     */
    getPosts = () => {
        console.log(this.props)
        let params = new URLSearchParams(this.props.location.search);
        let category = params.get("category")  
        let opportunitiesID = '9092b6fc-4732-4318-a757-0fb3e4ddca58'


        client.fetch(`*[_type == "post" && category._ref != "${opportunitiesID}"] | order(publishedAt desc)
        {_id, title, body, slug, mainImage, publishedAt, textPreview, "category": category->title, "author": author->name, "authorImage": author->image}`)
            .then(data => {           
                if(category !== null) {
                    let filteredData = []
                    data.forEach((dataItem) => {
                        if(dataItem.category._ref === category) {
                            filteredData.push(dataItem)
                        }
                    })
                    this.setState({posts: filteredData})
                } else {
                    this.setState({posts: data})
                }
                                             
            
            })
            .then(() => this.setState({isLoading: false}))
            .catch(err => console.log(err))
    }

    /**
     * Fetch categories drom Sanity data store
     * @see {@link https://www.sanity.io/docs/js-client} for more information
     */
    getCategories = () => {
        let opportunitiesID = '9092b6fc-4732-4318-a757-0fb3e4ddca58'

        client.fetch(`*[_type == "category" && _id == "${opportunitiesID}"]`)
        .then(data => {
            this.setState({categories: data})
        })
        .catch(err => console.log(err))
    }

    render() {
        let articles = this.state.posts
        let loaderStyle = {
                // position: 'absolute',
                display: 'block',
                width: '100%',
                height: '100vh',
                padding: '10%',
                margin: 'auto',
                backgroundColor: 'rgba(255,255,255,0.95)',
                zIndex: '999',
                textAlign: 'center',
                letterSpacing: '0.05em',
                color: '#52557a'
            }

        if(!this.state.isLoading) {
            loaderStyle = {display: 'none'}
        }

        return(
            <div>
                <Header 
                        pageName="Journal" 
                        bgColor="linear-gradient(230deg, #a24bcf, #d12d4c, #b01043) 0% 0% / 300% 300%" 
                        bgImage={HeaderImg}
                        title="Journal"
                        content={<p style={{fontSize: '1.1em', textAlign: 'center'}}>The latest news and upcoming events from Sleigh Group</p>}
                        flexDirection="column"
                        marginTop="50px"
                        paddingBottom="180px"
                        align="center"
                    />
                <div className="main-container" id="journal-container">
                        
                    <div className="main-content">
                        
                        {/* The ability to filter by categories will be added once more journal categories is available*/}
                        {/* <div className="breadcrumbs">
                            <Link to="/" style={{ textDecoration: 'none', fontWeight: 'bold', color: "black"}}>Home</Link> / Journal
                        </div> */}
                            
                            {/* <div className="journal-categories">
                                <ul className="category-list">
                                    <li key="all-category" onClick={this.reload}>
                                    <Link
                                        to={`/journal`}
                                        onClick={this.reload}>
                                            All
                                    </Link>
                                    </li>
                                    {categories.map((category, i) => {
                                        return (
                                            <li key={category._id} onClick={this.reload}>
                                            <Link
                                                to={{
                                                    pathname:`/journal`,
                                                    search: `?category=${category._id}`
                                                }}
                                                onClick={this.reload}>
                                                {category.title}
                                            </Link >
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div> */}
                            <div className="loader" style={loaderStyle}><h3>Loading posts...</h3></div>
                            <div className="article-collections" style={{marginTop: "80px"}}>
                                {/* Loop through articles and render previews of the articles*/}
                                {articles.map((article, i) => {
                                    let image, flexDirection, authorImage;

                                    // Change flex directio on smaller screes
                                    if(window.innerWidth > 768) {
                                        if(i % 2 === 0) {
                                            flexDirection = 'row-reverse'
                                        } else {
                                            flexDirection = 'row'
                                        }
                                    } else {
                                        flexDirection = 'column'
                                    }

                                    
                                    // Make sure the article data and repective images exist to avoid undefined errors before setting variables
                                    if(article.mainImage !== undefined) {
                                        image = urlFor(article.mainImage.asset._ref);
                                    } else {
                                        image = ""
                                    }

                                    if(article.authorImage !== undefined) {
                                        authorImage = urlFor(article.authorImage.asset._ref);
                                    } else {
                                        authorImage = ""
                                    }

                                    return(
                                        
                                        <div key={article._id}>
                                            
                                            <Link 
                                            to={`/journal/${article.slug.current}`} 
                                            state={{
                                                slug: article.slug.current
                                            }}>
                                                <ArticleCard
                                                    title={article.title}
                                                    subtitle={article.category}
                                                    backgroundImage={image}
                                                    direction= {flexDirection}
                                                    date={new Date(article.publishedAt).toDateString()}
                                                    content={article.textPreview}
                                                    author={article.author}
                                                    authorImage={authorImage}
                                                />
                                            </Link>
                                        </div>
                                        
                                    ) 
                                })}
                                
                            </div>
                                
                    </div>
                    
                </div>
            </div>
        )
    }
}