import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Journal from "../components/Journal/Journal"

const JournalPage = (props) => (
  <Layout >
    <SEO 
      title="Journal" 
      keywords="translation, localisation, localization, English to Tagalog, Tagalog to English, cultural translation, cultural naturalization, cultural naturalisation, production management, voice acting, voice actors, voice actor management, dubbing, voice over, Australia, Philippines, Sleigh Platform, subtitles, subtitling, language services, news"/>
    <Journal location={props.location} />
  </Layout>
)

export default JournalPage
